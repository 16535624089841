// src/actions/authActions.js
import { apiSlice } from "../services/api";

export const landingpageApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createlandingpage: builder.mutation({
            query: (formDataToSend) => ({
                url: "/landingpages",  // Creating a landing page (POST request)
                method: "POST",
                body: formDataToSend,
            }),
        }),
        getLandingPageDetails: builder.query({
            query: () => ({
                url: "/landingpage",  // Corrected typo in the URL (GET request)
                method: "GET",
            }),
        }),
    }),
});

export const {
    useCreatelandingpageMutation,
    useGetLandingPageDetailsQuery,
} = landingpageApiSlice;
