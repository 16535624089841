import { selectLandingpage } from '@/reducers/categoriesReducer';
import Link from 'next/link';
import React, { useState } from 'react';
import { FaStar, FaDollarSign, FaRegStar } from 'react-icons/fa'; // Import icons
import { useSelector } from 'react-redux';

export default function MostHiredFreelancers() {
    const landingPageData = useSelector(selectLandingpage);
    const [errorImages, setErrorImages] = useState({});

    // Handle image error by tracking which image has failed
    const handleImageError = (userId) => {
        setErrorImages((prev) => ({
            ...prev,
            [userId]: true, // Mark this specific user's image as having an error
        }));
    };

    return (
        <section className="most-hired-section md:py-12 px-6 bg-white">
            <div className="max-w-[1240px] w-full mx-auto">
                <div className="text-center mb-8" data-aos="fade-up">
                    <h2 className="text-3xl font-bold mb-2">Most Hired Freelancers</h2>
                    <p className="text-gray-700">Work with talented people at the most affordable price</p>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-6">
                    {landingPageData?.freelancers?.map((developer) => (
                        <div
                            key={developer.userId}
                            className="bg-white shadow-[0_4px_12px_-5px_rgba(0,0,0,0.4)] w-full max-w-sm rounded-lg overflow-hidden mx-auto font-[sans-serif] mt-4"
                        >
                            <img
                                src={developer.avatar}
                                alt={developer.firstName}
                                height={300}
                                width={300}

                                className={`w-full h-52 rounded-xl bg-gray-50 ${errorImages[developer.userId] ? 'object-cover animate-pulse bg-secondary2' : 'object-fill rounded-xl p-4'
                                    }`}
                                onError={(e) => {
                                    handleImageError(developer.userId)
                                    e.target.src = "https://flowbite.com/docs/images/carousel/carousel-2.svg"; // Fallback image
                                }}
                            />

                            <div className="p-4">
                                <div className="flex space-x-2">
                                    <Link className="font-bold text-base whitespace-nowrap" href="#">
                                        {developer.firstName} {developer.lastName.charAt(0)}.
                                    </Link>

                                    <div className="rating flex items-center">
                                        {/* Display rating with both filled and empty stars */}
                                        {[...Array(5)].map((_, index) => (
                                            index < developer.ratings ? (
                                                <FaStar key={index} className="text-yellow-300" />
                                            ) : (
                                                <FaRegStar key={index} className="text-yellow-300" />
                                            )
                                        ))}
                                        <span className="average-rating">({developer.ratings}.0)</span>
                                    </div>
                                </div>

                                <div>
                                    <span className="block">{developer.specialty}</span>
                                    <p className="flex items-center mt-2 text-flamingo-400">
                                        <FaDollarSign className="w-4 h-4 text-green-600 mr-1" />
                                        {developer.rate} /hr
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
