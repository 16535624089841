import React from 'react';

const features = [
    {
        icon: "/assets/img/great-icon-01.svg",
        title: "Browse Portfolios",
        description: "Find professionals you can trust by browsing their samples of previous work.",
    },
    {
        icon: "/assets/img/great-icon-03.svg",
        title: "Fast Bids",
        description: "Receive obligation-free quotes from our talented freelancers fast. 80% of projects get bid.",
    },
    {
        icon: "/assets/img/great-icon-03.svg",
        title: "Quality Work",
        description: "IworkFreelancer has by far the largest pool of quality freelancers globally - over 50 million to choose from.",
    },
    {
        icon: "/assets/img/great-icon-01.svg",
        title: "Track Progress",
        description: "Keep up-to-date and on-the-go with our time tracker. Always know what freelancers are up to.",
    },
];

export default function NeedWorkDone() {
    return (
        <section className="py-16 bg-gray-100">
            <div className="container mx-auto px-4">
                <div className="text-center mb-12">
                    <div className="section-header"
                        data-aos="fade-up"
                        data-aos-once="true"
                    >
                        <h2 className="text-3xl text-gray-900 font-bold mb-4">Need something done?</h2>
                        <p className="!text-gray-800">IworkFreelancer is here to help you get the job completed</p>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    {features.map((feature, index) => (
                        <div
                            key={index}
                            className="bg-white p-6 rounded-lg shadow-lg h-full"
                            data-aos="zoom-in"
                            data-aos-once="true"
                            data-aos-duration={1000 + index * 500} // Increment duration for each item
                        >
                            <div className="feature-icon mb-4">
                                <img src={feature.icon} className="img-fluid" alt={feature.title} />
                            </div>
                            <div className="feature-content text-center">
                                <h3 className="text-xl font-bold mb-2 text-gray-900">{feature.title}</h3>
                                <p className="text-gray-800">{feature.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
