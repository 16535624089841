import React from 'react'
import Herosection from './Herosection'
import NeedWorkDone from './NeedWorkDone'
import Achivements from './Achivements'
import MostHiredFreelancers from './MostHiredFreelancers'
import FAQs from './Faqs'
import { selectLandingpage } from '@/reducers/categoriesReducer'
import { useSelector } from 'react-redux'


export default function LandingPages() {



    return (
        <div className='bg-white'>
            <section className='w-full' id='home'>
                <Herosection />
            </section>
            <section>
                <NeedWorkDone />
            </section>
            <section >
                <Achivements />
            </section>
            <section>
                <MostHiredFreelancers />
            </section>
            <section>
                <FAQs />
            </section>
        </div>

    )
}