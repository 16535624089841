"use client"
import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaGraduationCap, FaLaptopCode, FaChalkboardTeacher, FaUserTie } from "react-icons/fa";
import Link from "next/link";

const Hero = () => {
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        AOS.init({
            duration: 800,
            easing: "ease-in-out",
            once: true,
        });

        const handleScroll = () => {
            setScrollY(window.scrollY);
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const userTypes = [
        {
            icon: <FaUserTie className="w-12 h-12 text-orange-500" />,
            title: "Clients",
            description: "Find top talent for your projects",
        },
        {
            icon: <FaLaptopCode className="w-12 h-12 text-orange-500" />,
            title: "Freelancers",
            description: "Discover exciting opportunities",
        },
        {
            icon: <FaChalkboardTeacher className="w-12 h-12 text-orange-500" />,
            title: "Tutors",
            description: "Share your knowledge",
        },
        {
            icon: <FaGraduationCap className="w-12 h-12 text-orange-500" />,
            title: "Students",
            description: "Learn from experts",
        },
    ];

    return (
        <>
            {/* Preload the image */}
            <link
                rel="preload"
                href="https://images.unsplash.com/photo-1522071820081-009f0129c71c?auto=format&fit=crop&w=1920&q=80&fm=webp"
                as="image"
            />
            <div className="relative min-h-screen overflow-hidden">
                <div
                    className="absolute inset-0 bg-cover bg-center bg-no-repeat"
                    style={{
                        backgroundImage:
                            "url('https://images.unsplash.com/photo-1522071820081-009f0129c71c?auto=format&fit=crop&w=1920&q=80&fm=webp')",
                        transform: `translateY(${scrollY * 0.5}px)`,
                    }}
                    aria-hidden="true"
                >
                    <div className="absolute inset-0 bg-gradient-to-r from-gray-900/90 to-gray-900/90" />
                </div>

                <div className="relative container mx-auto px-4 py-28 min-h-screen flex flex-col justify-center">
                    <div data-aos="fade-up" className="text-center mb-8">
                        <h1 className="text-3xl sm:text-5xl pt-2 md:text-6xl font-bold text-white mb-6">
                            Connect. Collaborate. Create.
                        </h1>
                        <p className="text-base sm:text-lg md:text-2xl text-gray-200 mb-8">
                            Your gateway to a world of talent and opportunities
                        </p>
                        <Link
                        href="/register"
                            aria-label="start"
                            className="bg-orange-500 hover:bg-orange-600 text-white px-8 py-3 rounded-full font-semibold text-lg shadow-lg hover:shadow-xl transition-all duration-300"
                        >
                            Get Started Now
                        </Link>
                    </div>

                    <div
                        data-aos="fade-up"
                        data-aos-delay="200"
                        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-12"
                    >
                        {userTypes.map((type, index) => (
                            <div
                                key={type.title}
                                className="bg-white/10 backdrop-blur-lg rounded-xl p-6 text-center"
                                data-aos="zoom-in"
                                data-aos-delay={index * 100}
                            >
                                <div className="text-white mb-4 flex justify-center">
                                    {type.icon}
                                </div>
                                <p className="text-xl font-semibold text-white mb-2">
                                    {type.title}
                                </p>
                                <p className="text-gray-300">{type.description}</p>
                            </div>
                        ))}
                    </div>

                    <div
                        data-aos="fade-up"
                        data-aos-delay="500"
                        className="absolute bottom-8 left-1/2 transform -translate-x-1/2"
                    >
                        <div className="animate-bounce text-white">
                            <svg
                                className="w-6 h-6"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Hero;
