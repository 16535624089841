import { createSlice } from "@reduxjs/toolkit";
import Cookies from 'js-cookie';

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
    refreshToken: null,
    users: [],
    roles: [],
    email: '',
    otp: "",
    isOnboarded: false,
    canResend: false,
    isLoggedIn: false,
    resendTimer: 10,
    selectRole: null,
    open: false,
    projectId: null,
    activeRole: null,
    inactiveRoles: [],
  },
  reducers: {
    setRoles: (state, roles) => {
      if (roles.includes("Freelancer")) {
        state.activeRole = "Freelancer";
        state.inactiveRoles = roles.filter(role => role !== "Freelancer");
      } else if (roles.includes("Client")) {
        state.activeRole = "Client";
        state.inactiveRoles = roles.filter(role => role !== "Client");
      } else {
        state.activeRole = roles[0]; // Fallback to first role if no Freelancer or Client
        state.inactiveRoles = roles.filter(role => role !== state.activeRole);
      }
    },

    setCredentials: (state, action) => {
      const { user, token, refreshToken, roles } = action.payload;
      state.user = user;
      state.isLoggedIn = true;
      state.token = token;
      state.refreshToken = refreshToken;
      state.roles = roles;
      state.isOnboarded = user.isOnboarded;

      // Set active and inactive roles
      authSlice.caseReducers.setRoles(state, roles);

      Cookies.set('token', token, { expires: 7 });
      Cookies.set('roles', JSON.stringify(roles), { expires: 7 });
    },

    setOpen: (state, action) => {
      state.open = action.payload;
    },

    setProjectId: (state, action) => {
      state.projectId = action.payload;
    },
    setIsOnboarded: (state, action) => {
      state.isOnboarded = action.payload;
      if (state.user) {
        state.user.isOnboarded = action.payload; // Ensure this is set correctly in the user object
      }
    }
    ,

    setIsPaymentConnected: (state, action) => {
      if (state.user) {
        state.user.paymentConnected = action.payload; // Ensure this is set correctly in the user object
      }
    }
    ,
    setGoogleCredentials: (state, action) => {
      const { userData, token } = action.payload;
      state.user = userData.user;
      state.token = token;
      state.isLoggedIn = true;
      state.refreshToken = userData.user.refreshToken;
      state.roles = userData.user.roles;

      // Set active and inactive roles
      authSlice.caseReducers.setRoles(state, userData.user.roles);

      Cookies.set('token', token, { expires: 7 });
      Cookies.set('roles', JSON.stringify(userData.user.roles), { expires: 7 });
    },

    setCurrentUser: (state, action) => {
      state.user = action.payload;
    },

    setIsPaymentConnected: (state, action) => {
      if (state.user) {
        state.user.paymentConnected = action.payload; // Ensure this is set correctly
      }
    },

    setRole: (state, action) => {
      state.selectRole = action.payload;
    },

    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },

    logOut: (state) => {
      state.token = null;
      state.user = null;
      state.roles = [];
      state.activeRole = // Reset to default role on logout
        state.inactiveRoles = []; // Reset inactive roles
      state.isLoggedIn = false;
      Cookies.remove('token');
      Cookies.remove('roles');
      state.refreshToken = null;
    },

    setUsers: (state, action) => {
      state.users = action.payload;
    },

    resetForgetPassword: (state, action) => {
      state.user = null;
      state.message = action.payload.message;
    },
    addInactiveRole: (state, action) => {
      state.inactiveRoles.push(action.payload);
      state.roles.push(action.payload);
    },

    register: (state, action) => {
      state.user = action.payload.user;
      state.token = null;
    },

    updateUser: (state, action) => {
      const updatedIndex = state.users.findIndex(
        (user) => user.id === action.payload.id
      );
      if (updatedIndex !== -1) {
        state.users[updatedIndex] = action.payload;
      }
    },

    setCanResend: (state, action) => {
      state.canResend = action.payload;
    },

    decrementResendTimer: (state) => {
      if (state.resendTimer > 0) {
        state.resendTimer -= 1; // Prevent going below zero
      }
    },

    resetResendTimer: (state) => {
      state.resendTimer = 10;
    },

    switchRole: (state, action) => {
      const newRole = action.payload; // The new role to switch to

      // Check if the new role is part of the available roles
      if (state.roles.includes(newRole)) {
        // Add the currently active role to the inactive roles
        if (state.activeRole) {
          state.inactiveRoles.push(state.activeRole);
        }

        // Set the new active role
        state.activeRole = newRole;

        // Remove the new role from the inactive roles (if it exists)
        state.inactiveRoles = state.inactiveRoles.filter(role => role !== newRole);

        // Update the inactive roles list
        state.inactiveRoles = state.roles.filter(role => role !== newRole);
      }
    },

    setEmail: (state, action) => {
      state.email = action.payload;
    },

    setOtp: (state, action) => {
      state.otp = action.payload;
    },
  },
});

export const {
  setCredentials,
  setIsLoggedIn,
  setOpen,
  logOut,
  resetForgetPassword,
  register,
  updateUser,
  setCanResend,
  decrementResendTimer,
  resetResendTimer,
  setEmail,
  setOtp,
  setIsOnboarded,
  setGoogleCredentials,
  setCurrentUser,
  setRole,
  setProjectId,
  addInactiveRole,
  setUsers,
  switchRole,
  setIsPaymentConnected,
} = authSlice.actions;

export default authSlice.reducer;

// Selectors
export const selectCanResend = (state) => state.auth.canResend;
export const selectProjectId = (state) => state.auth.projectId;
export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectResendTimer = (state) => state.auth.resendTimer;
export const selectCurrentUser = (state) => state.auth.user;
export const selectUsers = (state) => state.auth.users;
export const selectOpen = (state) => state.auth.open;
export const selectCurrentToken = (state) => state.auth.token;
export const selectUserRoles = (state) => state.auth.roles;
export const selectCurrentRefreshToken = (state) => state.auth.refreshToken;
export const selectEmail = (state) => state.auth.email;
export const selectOtp = (state) => state.auth.otp;
export const selectUserOnboardingStatus = (state) => state.auth.isOnboarded;
export const selectActiveRole = (state) => state.auth.activeRole; // New selector for active role
export const selectInactiveRoles = (state) => state.auth.inactiveRoles; // New selector for inactive roles
