"use client";

import React, { useEffect } from "react";
import LandingPageLayout from "@/components/landingpage/langingpageLayout";
import LandingPages from "@/components/landingpage/Template";
import { useGetLandingPageDetailsQuery } from "@/actions/LandingpageDetails";
import { useDispatch, useSelector } from "react-redux";
import { selectLandingpage, setLandingpage } from "@/reducers/categoriesReducer";

export default function Page() {
  const dispatch = useDispatch();

  // Select state from Redux store
  const landingPageData = useSelector(selectLandingpage);

  // Query hook for landing page details
  const { data: landingPageDetails } = useGetLandingPageDetailsQuery();

  useEffect(() => {
    if (landingPageDetails) {
      // Dispatch the landing page details to Redux store when data is available
      dispatch(setLandingpage(landingPageDetails));
    }
  }, [landingPageDetails, dispatch]);

  return (
    <LandingPageLayout>
      <LandingPages landingPageData={landingPageData} />
    </LandingPageLayout>
  );
}
